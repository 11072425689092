@import 'Styles/variables';

.slide {
    width: 100%;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    border: 4px solid $red;

    .overlay {
        background: #fbb0b6cf;

        h2 {
            color: $red; }

        .button {
            color: $red; } }

    .mobileTop {
        display: none; }

    &.playing {
        .overlay {
            visibility: hidden; } }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
        z-index: 1;

        .button {
            border-radius: 30px;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            background: white;
            box-shadow: 0px 5px 10px rgba(0,0,0,0.15);

            span {
                padding-right: 10px; } } }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 16/9; } }





@media(max-width: 1024px) {

    .slide {
        height: auto;
        border-radius: 0;
        border: 0 !important;

        .mobileTop {
            max-width: 380px;
            height: 100px;
            display: revert;
            display: flex;
            align-items: center;
            grid-gap: 10px;
            font-weight: bold;
            font-size: 30px;
            line-height: 1.1;

            svg {
                width: auto;
                height: 60px;
                margin: 0 10px 0 0; } }

        .overlay {
            height: 500px;
            border-radius: 10px;

            h2 {
                display: none; } }

        video {
            height: 500px;
            border-radius: 10px; } } }





@media(max-width: 500px) {
    .slide {
        video {
            height: auto; }

        .overlay {
            height: calc(100% - 100px); } } }
