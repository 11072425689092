@import 'Styles/variables';

.footer {
    height: 100px;
    display: flex;
    justify-content: center;
    background: $darkGrey;
    color: white;
    font-size: 16px;
    z-index: 2;

    .container {
        display: flex;
        align-items: flex-end;
        padding: 30px;

        > div {
            margin-bottom: -2px; } }

    svg {
        width: 190px;
        display: block;
        margin: 0 20px 0 0;

        path {
            fill: white; } } }





@media(max-width: 500px) {

    .footer {
        height: 120px;

        .container {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 20px; }

        svg {
            width: 160px;
            display: block;
            margin: 0 0 10px; } } }
