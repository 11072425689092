@import 'Styles/variables';

.Hero {
    background: $red;
    color: white;
    z-index: 3;

    &.in-view {
        .Hero-content {
            opacity: 1;
            transform: scale(1); }

        .Hero-image {
            opacity: 1;
            transform: scale(1); } }

    .container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        max-height: calc( 100vh - 70px );
        position: relative;

        &.topHero {
            .Hero-image {
                margin: 0; } } }

    &-image {
        display: flex;
        justify-content: flex-end;
        width: auto;
        max-height: 100%;
        margin: -100px -50px 0 0;
        opacity: 0;
        transform: translateX(-20px);
        transition: 1s;

        img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            z-index: 1; } }

    &-content {
        width: 45%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        text-align: left;
        padding-bottom: 30px;
        opacity: 0;
        transform: translateY(10px);
        transition: 1s;

        &-title {
            padding-bottom: 2%; }

        &-copy {
            width: 100%;
            font-size: 18px;
            margin-bottom: 10px;

            p {
                margin: 0;
                width: 80%; } }

        &-subcopy {
            width: 100%;
            font-size: 14px;
            margin-top: 20px;

            p {
                margin: 0;
                width: 80%; } }

        .button {
            margin: 10px 0 0; }

        strong.Hero-content-copy {
            font-size: 56px;
            margin-top: 30px;
            line-height: 1;
            padding-bottom: 10px;
            padding-top: 10px; } } }





@media(max-width: 1024px) {

    .Hero {
        padding: 50px 0 0;
        height: 100%;

        .container {
            max-height: inherit;
            flex-direction: column;
            align-items: flex-end;

            .Hero-image {
                width: 170%;
                margin-right: -50%; }

            &.topHero {
                .Hero-image {
                    width: 100%;
                    margin-right: 0; } } }

        &-content {
            width: 100%;
            margin-bottom: 10px;
            margin-left: 0px;
            padding-bottom: 0;
            z-index: 1;

            &-copy {
                p {
                    width: 90%;
                    font-size: 18px; } }

            .button {
                width: 100%;
                font-size: 24px;
                padding: 14px 30px;
                box-shadow: 0 0 10px rgba(black, 0.2); }

            strong.Hero-content-copy {
                font-size: 48px;
                width: 90%;
                margin-top: 0; } } } }
