h1, h2, h3, h4, h5, h6 {
    font-weight: bold; }

h1 {
    font-size: 55px;
    line-height: 1.2; }





@media(max-width: 500px) {

    h1 {
        font-size: 42px; } }
