@import 'Styles/variables';

.VideoAndText {
    padding: 130px 0 70px;
    background-color: white;
    z-index: 2;

    &.in-view {
        .VideoAndText-video, .VideoAndText-content {
            opacity: 1;
            transform: translateY(0px); } }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        > svg {
            position: absolute;

            &:first-of-type {
                width: 20%;
                left: 5%;
                top: -130px; }

            &:last-of-type {
                width: 20%;
                left: 70%;
                bottom: -245px;
                z-index: -1; } } }

    &-video, &-content {
        transition: 0.8s;
        transform: translateY(25px);
        opacity: 0; }

    &-video {
        width: 75%;
        display: flex;
        justify-content: center;
        transition-delay: 0.3s;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.15);
        border-radius: 5px;

        &-decoration {
            display: block;
            width: 330px;
            height: 300px;
            position: absolute;
            top: -150px;
            left: -150px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat; }

        &-cont {
            width: 100%;
            height: auto;
            overflow: hidden;
            position: relative;

            video {
                width: 100%;
                position: relative;
                border-radius: 5px;
                filter: brightness(0.4);

                &.playing {
                    filter: brightness(1); } }

            .placeholderBox {
                width: 100%;
                height: 100%;
                background: lightGrey;
                position: absolute;
                left: 0;
                bottom: 0; }

            .playButton {
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transform-origin: cenetr;
                transition: 0.3s;

                svg {
                    display: block;
                    width: 110px;
                    height: 90px;
                    margin: 20px;

                    path {
                        stroke: white; } }

                &.playing {
                    display: none; }

                &:hover {
                    transform: scale(1.1); } } } }

    &-content {
        width: 100%;
        transition-delay: 0.15s;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
        z-index: 1;

        &-title {
            font-size: 55px;
            width: 50%;
            line-height: 1;
            padding-bottom: 20px;

            em {
                font-style: inherit;
                color: $purple; } }

        &-copy {
            font-size: 23px;
            width: 60%;
            line-height: 1.5;
            letter-spacing: 0.1px; } } }





@media(max-width: 2000px) {

    .VideoAndText {
        .container {
            > svg {
                width: 800px;
                bottom: -357px;
                left: calc( 50% + 50px );

                path {
                    stroke-width: 6px; } } } } }





@media(max-width: 1024px) {

    .VideoAndText {
        padding: 60px 0;

        .container {
            flex-direction: column;

            > svg {
                display: none; } }

        &-video {
            width: 100%;
            height: 100%;

            &-cont {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative; } }

        &-content {
            width: 100%;
            padding-top: 0;

            &-title {
                width: 100%; }

            &-copy {
                width: 100%; } } } }





@media(max-width: 500px) {

    .VideoAndText {
        padding: 40px 0 60px;

        &-video {
            height: auto;

            &-cont {
                height: inherit;

                .playButton {
                    justify-content: center;
                    align-items: center;
                    transform-origin: center; } }

            &-decoration {
                width: 100px;
                height: 100px;
                top: auto;
                bottom: -50px;
                left: -50px;
                transform: scaleY(-1); } }

        &-content {
            padding-top: 0;
            padding-left: 0px;
            padding-bottom: 0;

            &-title {
                width: 100%;
                font-size: 40px;
                line-height: 1.2; }

            &-copy {
                font-size: 24px; } } } }
