@import 'Styles/variables';

.Timeline {
    h1 {
        position: absolute;
        bottom: 100%;
        z-index: 2;
        text-align: center;
        background: white;
        left: 50%;
        transform: translate(-50%, 50%);
        padding: 20px 40px;
        z-index: 10;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.05);
        white-space: nowrap;

        span {
            color: $blue; } }

    .inner {
        background: $lightGrey; }

    .container {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 150px 0; }

    &-squiggleCont {
        position: absolute;
        height: calc( 100% + 154px );
        width: 100px;
        top: 0;
        left: 50%;
        display: flex;
        justify-content: center;
        margin-left: -50px;
        background-image: url(../../../Images/timelineSquiggle2.svg);
        background-size: 100%;
        z-index: 4;

        .squiggle {
            height: 100%;
            top: -0.2%;
            left: 0.1%;
            height: calc(100% + 4px); } }

    &-bigTitles {
        width: 80%;
        height: calc(100vh - 70px);
        position: fixed;
        top: 70px;
        left: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        &-inner {
            position: sticky;
            width: 100%;
            height: 560px;

            &-title {
                position: absolute;
                top: 0;
                font-size: 68px;
                color: $blue;
                -webkit-text-fill-color: #ffffff00;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: $blue;

                &:first-of-type {
                    writing-mode: vertical-lr;
                    transform: rotate(180deg);
                    left: 0; }

                &:last-of-type {
                    writing-mode: vertical-rl;
                    right: 0; }

                span {
                    &:nth-child(2) {
                        height: 50%;
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        -webkit-text-fill-color: $blue;
                        white-space: nowrap; } }

                &:nth-child(1) {
                    span {
                        &:nth-child(2) {
                            padding-top: 200px;
                            transform: translateY(-200px); } } }

                &:nth-child(2) {
                    span {
                        &:nth-child(2) {
                            padding-top: 600px;
                            transform: translateY(-600px); } } } } } }

    &-years {
        z-index: 5;

        &-cont {
            width: 100px;
            margin: 0 auto; }

        &-year {
            display: flex;
            justify-content: center;
            padding-bottom: 150px;
            position: relative;

            &.left {
                .blueDot {
                    span {
                        left: auto;
                        right: -45px; }

                    .contentBox {
                        left: auto;
                        right: 10px; } } }

            &:last-of-type {
                padding-bottom: 0; }

            .blueDot {
                background: $blue;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                position: relative;
                transition: transform 0.3s;
                cursor: pointer;

                span {
                    position: absolute;
                    font-size: 22px;
                    font-weight: bold;
                    left: -80px;
                    width: 100%;
                    display: flex;
                    align-items: flex-start;

                    i {
                        font-size: 18px; } }

                .contentBox {
                    opacity: 0;
                    transform: scale(0.8) translateY(-50%);
                    position: absolute;
                    padding: 20px;
                    background: white;
                    width: 550px;
                    left: 10px;
                    top: 0;
                    border: 1px solid $blue;
                    box-shadow: 2px 2px 10px 2px #0000001a;
                    transition: 0.3s;

                    img {
                        max-width: 200px;
                        max-height: 50px;
                        margin-bottom: 10px; }

                    .copy {
                        font-size: 20px;

                        p {
                            margin: 10px 0; }

                        ul li {
                            margin-top: 10px; } }

                    &::before {
                        content: attr(data-year);
                        position: absolute;
                        top: -71px;
                        right: 0px;
                        font-size: 70px;
                        color: $blue;
                        font-weight: bold; } }

                &:hover {
                    transform: scale(1.1);

                    .contentBox {
                        opacity: 1; } } } } } }





@media(max-width: 1024px) {

    .Timeline {
        &-years {
            &-year {
                .blueDot {
                    .contentBox {
                        max-width: 310px; } } } } } }





@media(max-width: 500px) {

    .Timeline {
        height: 100%;

        h1 {
            top: 0;
            background: transparent;
            backdrop-filter: none;
            box-shadow: none;
            width: 100%;
            text-align: left;
            padding-left: 30px;
            white-space: normal; }

        &-squiggleCont {
            display: none; }

        .container {
            padding: 50px 0; }

        &-bigTitles {
            height: auto;
            position: relative;
            padding-bottom: 40px;
            top: 0;

            &-inner {
                height: 100%;
                position: relative;
                top: 0;

                &-title {
                    text-align: center;
                    position: relative;
                    font-size: 35px;
                    -webkit-text-fill-color: $blue;
                    -webkit-text-stroke-width: $blue;
                    -webkit-text-stroke-color: $blue;

                    &:first-of-type {
                        writing-mode: inherit;
                        transform: rotate(0deg);
                        left: 0; }

                    &:last-of-type {
                        writing-mode: inherit;
                        right: 0; } }

                span {
                    &:nth-child(2) {
                        display: none; } } } }

        &-years {
            position: relative;
            height: 630px;
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;

            &-cont {
                height: 100%;
                padding: 60px 30px 60px;
                display: flex;
                align-items: flex-end;
                width: max-content; }

            &-year {
                height: 100%;
                padding: 0 130px 50px;
                align-items: flex-end;

                &.first {
                    margin-left: -10px; }

                &.left {
                    .blueDot {
                        span {
                            left: 0;
                            bottom: -50px;
                            left: -20px; }

                        .contentBox {
                            left: -88px;
                            right: auto; } } }

                &:last-of-type {
                    padding-bottom: inherit; }

                .blueDot {
                    .contentBox {
                        width: 260px;
                        opacity: 1;
                        top: auto;
                        bottom: 40px;
                        left: -115px !important;
                        height: auto;
                        padding: 14px;
                        padding-bottom: 0px;
                        transform: scale(1);

                        &:after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 30px;
                            background: linear-gradient(to top, white, rgba(255,255,255,0));
                            z-index: 2;
                            content: '';
                            pointer-events: none; }

                        img {
                            max-width: 140px; }

                        .copy {
                            font-size: 16px;
                            padding-top: 0px;
                            margin-top: -7px;
                            max-height: 270px;
                            overflow: auto;

                            &>*:last-child {
                                margin-bottom: 15px; }

                            ul {
                                padding-left: 23px; }

                            p {
                                margin: 5px 0; } }

                        &::before {
                            right: 2px;
                            font-size: 50px;
                            top: -52px; } }

                    span {
                        position: absolute;
                        font-size: 24px;
                        left: 0;
                        bottom: -50px;
                        left: -20px; }


                    &:hover {
                        transform: scale(1); } } }

            &-squiggleCont {
                width: 100%;
                position: absolute;
                left: 0;
                background-image: url(../../../Images/timelineSquiggle2-mobile.svg);
                height: 100px;

                .squiggle {
                    width: 100%; } } } } }
