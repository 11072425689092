$red: #D90432;
$brightRed: #EE0032;
$blue: #1378D1;
$darkBlue: #153C6A;
$darkGrey: #262626;
$midGrey: #D3D3D3;
$lightGrey: #D3D3D3;
$purple: #8A59AD;
$lightGrey: #F8F8F8;
$beige: #FFF9F1;
