@import 'Styles/variables';

.button {
    background: $red;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 18px;
    border: none;
    color: white;
    appearance: none;
    cursor: pointer;
    transition: 0.3s;

    a {
        transition: 0.3s;
        cursor: pointer; }

    &.disabled {
        opacity: 0.5;
        cursor: initial; }

    &.white {
        background-color: white;
        color: black;

        a {
            color: black;
            text-decoration: none; }

        &:hover {
            a {
                color: white; } } }

    &:hover {
        background: darken($red, 5%); } }
