@import 'Styles/variables';

.VideoSlider {
    background: white;
    overflow: hidden;

    &.in-view {
        .VideoSlider {
            .container {
                h1, .copy, .sliderContainer, .iconContainer > div {
                    opacity: 1;
                    transform: scale(1); } } } }

    .container {
        padding: 160px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        > svg {
            position: absolute;
            width: 60%;
            top: -90px;
            left: 5%; }

        h1 {
            color: $red;
            text-align: center;
            width: 60%;
            margin: 0;
            padding-bottom: 30px;
            background: white;
            opacity: 0;
            transform: translateY(10px);
            transition: 1s; }

        .copy {
            width: 40%;
            text-align: center;
            padding-bottom: 30px;
            opacity: 0;
            transform: translateY(10px);
            transition: 1s; }

        .sliderContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            grid-gap: 20px;
            opacity: 0;
            transform: translateY(10px);
            transition: 1s;
            transition-delay: 0.2s;

            .controlContainer {
                display: flex;
                align-items: center;
                cursor: pointer;

                i {
                    color: $red;
                    font-size: 60px;
                    font-weight: bold; } }

            .sliderInner {
                width: 75%; } }

        .iconContainer {
            display: flex;
            justify-content: space-around;
            width: 65%;
            margin-top: 60px;
            margin-bottom: 70px;
            z-index: 10;

            > div {
                width: 210px;
                height: 210px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                padding: 60px;
                color: black;
                cursor: pointer;
                opacity: 0;
                transform: translateY(10px);
                transition: 1s;

                &:hover {
                    > svg {
                        transform: scale(1.2); }

                    > span {
                        transform: scale(1.1); } }

                &.yellow {
                    transition-delay: 0.4s;

                    > svg {
                        path {
                            &:first-child {
                                fill: #ffb612; }

                            &:last-child {
                                fill: #ebde00; } } }

                    > span {
                        color: black; } }

                &.blue {
                    transition-delay: 0.5s;

                    > svg {
                        path {
                            &:first-child {
                                fill: #73cff7; }

                            &:last-child {
                                fill: #007975; } } } }

                &.pink {
                    transition-delay: 0.6s;

                    > svg {
                        path {
                            &:first-child {
                                fill: #6e1245; }

                            &:last-child {
                                fill: #bc007b; } } } }

                &.green {
                    transition-delay: 0.7s;

                    > svg {
                        path {
                            &:first-child {
                                fill: #32a43d; }

                            &:last-child {
                                fill: #145629; } } } }

                > svg {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: 0.6s; }

                > span {
                    font-family: 'Kalam';
                    font-weight: bold;
                    line-height: 1.2;
                    color: white;
                    transition: 0.4s; } }

            img {
                width: 130px;
                height: 130px;
                transition: transform 0.3s;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1); }

                &.active {
                    transform: scale(1.5); } } } }

    &-decoration1 {
        display: block;
        width: 400px;
        height: 400px;
        position: absolute;
        top: 135px;
        right: -50px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }

    &-decoration2 {
        display: block;
        width: 320px;
        height: 320px;
        position: absolute;
        bottom: 0;
        left: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; } }





@media(max-width: 1024px) {

    .VideoSlider {
        .container {
            align-items: flex-start;
            padding: 60px 20px 30px;

            > svg {
                display: none; }

            h1 {
                width: 240px;
                font-size: 30px;
                text-align: left; }

            .copy {
                width: 100%;
                text-align: left; }

            .sliderContainer {
                margin: 0;

                .controlContainer {
                    display: none; }

                .sliderInner {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    grid-gap: 20px; } }

            .iconContainer {
                display: none; } }

        &-decoration1 {
            width: 140px;
            height: 140px;
            top: 30px;
            right: -20px; }

        &-decoration2 {
            display: none; } } }

