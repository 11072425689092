@import 'Styles/variables';

.ImageAndText {
    padding: 50px 0;
    background-color: white;
    z-index: 3;

    &.in-view {
        .ImageAndText-content {
            opacity: 1;
            transform: scale(1); }

        .ImageAndText-image {
            opacity: 1;
            transform: scale(1); } }

    .container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        max-height: calc( 100vh - 70px );
        position: relative; }

    &-image {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        max-height: 100%;
        margin: 0;
        opacity: 0;
        transform: translateX(-20px);
        transition: 1s;

        img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            z-index: 1; } }

    &-content {
        width: 35%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        text-align: left;
        padding-bottom: 30px;
        opacity: 0;
        transform: translateY(10px);
        transition: 1s;

        &-copy {
            width: 100%;
            font-size: 18px;
            margin: 10px 0;

            p {
                margin: 0;
                width: 80%; } } } }





@media(max-width: 1024px) {

    .ImageAndText {
        padding: 70px 0;

        .container {
            max-height: inherit;
            flex-direction: column;
            align-items: flex-end; }

        &-content {
            width: 100%;
            margin-bottom: 10px;
            margin-left: 0;
            padding-bottom: 0;
            z-index: 1;
            text-align: center;

            &-copy {
                p {
                    width: 90%;
                    font-size: 18px; } } }

        &-image {
            width: 100%; } } }
