@import 'variables';
@import 'typography';

html, body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    overscroll-behavior-x: contain; }

body {
    font-family: 'din-2014', sans-serif;
    font-size: 23px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    outline: none;
    color: #444; }

*, *:before, *:after {
    position: relative;
    outline: none;
    box-sizing: border-box;
    margin: 0; }

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%; }

input, button, textarea, select {
    font: inherit; }

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word; }

h2 {
    font-size: 36px; }

.flexible-content {
    overflow: hidden; }

.container {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    padding: 0 30px; }

.mainloading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    transition: 0.5s;

    &.hidden {
        opacity: 0;
        visibility: hidden; }

    svg {
        display: block;
        width: 100px;
        height: 100px; } }




@media(max-width: 500px) {
    h2 {
        font-size: 26px; }

    .container {
        padding: 0px 20px; } }
