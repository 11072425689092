@import 'Styles/variables';

.Text {
    padding: 40px 0 0;
    background: $darkGrey;
    color: white;
    z-index: 3;

    .container {
        display: flex;
        justify-content: space-between;
        position: relative; }

    &-content {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        text-align: left;

        &-copy {
            width: 100%;
            font-size: 16px;
            margin: 10px 0;

            p {
                margin: 0;
                width: 80%; } }

        a {
            color: white;
            text-decoration: underline;

            &:hover {
                text-decoration: none; } } } }





@media(max-width: 1024px) {

    .Text {
        .container {
            max-height: inherit;
            flex-direction: column;
            align-items: flex-end; }

        &-content {
            width: 100%;
            margin-bottom: 10px;
            margin-left: 0;
            padding-bottom: 0;
            z-index: 1;

            &-copy {
                font-size: 14px; } } } }
