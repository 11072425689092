@import 'Styles/variables';

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0,0,0,0.7);
    transition: 0.3s;
    transform: scale(1.05);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &.visible {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: scale(1); }

    &>div {
        width: 100%;
        background: white;
        box-shadow: 0 5px 30px rgba(0,0,0,0.2);
        overflow: hidden;
        width: 100%;
        max-width: 800px;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;

        .close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            border: none;
            appearance: none;
            background: rgba(0,0,0,0);
            font-size: 28px;
            color: white;
            padding: 16px;
            transition: 0.1s;
            cursor: pointer;
            outline: none;

            &:hover {
                opacity: 0.8; } }

        &.SUBMISSION {
            color: black;
            max-width: 1000px;

            input, textarea {
                border: none;
                -webkit-appearance: none;
                box-shadow: inset 0px 2px 5px rgba(0,0,0,0.1);
                -webkit-box-shadow: inset 0px 2px 5px rgba(0,0,0,0.1);
                background: #fafafa;
                border-radius: 5px; }

            textarea {
                padding: 15px;
                height: 170px;
                width: 100%;
                font-size: 20px; }

            .Submission {
                .flickity-viewport {
                    transition: 0.3s; }

                &-slide {
                    width: 100%;

                    &-title {
                        width: 100%;
                        padding: 16px;
                        padding-left: 27px;
                        background-color: $blue;
                        color: white;
                        font-weight: bold;
                        font-size: 28px; }

                    &-main {
                        padding: 30px; }

                    &-vision, &-value {
                        min-height: 300px;
                        display: flex;
                        justify-content: space-between;

                        &-main {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            width: auto;
                            flex-grow: 1;

                            &-fields {
                                position: relative;
                                width: 100%;
                                display: flex;
                                justify-content: space-between;

                                .selected-image {
                                    width: 240px;

                                    div {
                                        cursor: pointer;
                                        border-radius: 5px;
                                        overflow: hidden;

                                        &:hover {
                                            &:before {
                                                opacity: 1; }

                                            .button {
                                                opacity: 1;
                                                transform: translate(-50%, -50%); } }

                                        &:before {
                                            content: '';
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            z-index: 2;
                                            background: rgba(0,0,0,0.35);
                                            opacity: 0;
                                            transition: 0.2s; }

                                        img {
                                            height: 200px;
                                            object-fit: cover;
                                            object-position: center;
                                            width: 100%;
                                            display: block;
                                            z-index: 1; }

                                        .button {
                                            z-index: 3;
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -40%);
                                            border: 1px solid white;
                                            background: transparent;
                                            color: white;
                                            opacity: 0;
                                            transition: 0.2s; } } }

                                > div {
                                    width: calc( 100% - 265px );

                                    &.gallery-selector {
                                        width: 240px;
                                        flex-shrink: 0;
                                        border: 1px dashed $blue;
                                        background: #fafafa;
                                        border-radius: 7px;
                                        text-align: center;
                                        padding: 20px;
                                        cursor: pointer;
                                        transition: 0.15s;

                                        &:hover {
                                            background: white;

                                            .button {
                                                background: $blue; } }

                                        img {
                                            margin: auto;
                                            width: 80px;
                                            margin-bottom: 15px; }

                                        strong, span {
                                            display: block;
                                            font-size: 18px; }

                                        span {
                                            font-size: 16px;
                                            opacity: 0.6; }

                                        .button {
                                            background: $darkBlue;
                                            padding: 6px 15px;
                                            margin-top: 15px; } } } }

                            label {
                                display: block;
                                color: black;
                                font-weight: bold;
                                margin: -3px 0 15px;
                                cursor: pointer; }

                            .maxChars {
                                color: $red;
                                font-size: 16px;
                                position: absolute;
                                bottom: -25px;
                                left: 5px; }

                            .tags {
                                width: 100%;
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                grid-gap: 10px;

                                &>div {}

                                .tag {
                                    width: 100%;
                                    cursor: pointer;
                                    text-align: center; } } }

                        .Submission-slide-controls {
                            margin: 20px 0 0; }

                        &-vision {
                            .Submission-slide-controls {
                                .button {
                                    background: $red; } } } }

                    &-image {
                        label {
                            font-weight: bold;
                            margin-bottom: 5px; }

                        .subtitle {
                            font-size: 18px;
                            display: block;
                            color: #999;
                            margin-bottom: 25px; }

                        ul {
                            display: flex;
                            flex-wrap: wrap;
                            list-style: none;
                            padding: 0px;
                            margin-bottom: 30px;

                            li {
                                width: calc( 20% - 10px );
                                margin: 5px;
                                min-width: 1px;
                                border-radius: 5px;
                                overflow: hidden;
                                box-shadow: 0px 3px 10px rgba(0,0,0,0.07);
                                outline: 3px solid transparent;
                                cursor: pointer;
                                transition: 0.15s;

                                &:hover, &.selected {
                                    outline-color: $blue; }

                                &.selected {
                                    span {
                                        color: $blue; }

                                    i {
                                        opacity: 1;
                                        transform: scale(1); } }

                                > div {
                                    border: 5px solid $red;
                                    padding-bottom: 58%;
                                    background-position: center;
                                    background-size: cover;
                                    overflow: hidden;

                                    div {
                                        position: absolute;
                                        bottom: -18px;
                                        right: -6px;
                                        width: 60px;
                                        height: 60px;
                                        background-size: 100%;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        z-index: 2; }

                                    span {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        border: 3px solid white;
                                        z-index: 1; } }

                                img {
                                    display: block;
                                    height: 120px;
                                    object-fit: cover;
                                    object-position: center;
                                    width: 100%; }

                                span {
                                    display: block;
                                    font-size: 16px;
                                    padding: 12px;
                                    transition: 0.15s; }

                                i {
                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    padding: 10px;
                                    background: $blue;
                                    border-top-left-radius: 5px;
                                    transition: 0.2s;
                                    opacity: 0;
                                    transform: scale(0.5);
                                    transform-origin: bottom right;

                                    img {
                                        width: 18px;
                                        height: auto;
                                        display: block;
                                        margin-right: -5px; } } } } }

                    &-details {
                        &-fields {
                            display: flex;
                            justify-content: space-between;
                            margin: 20px 0;
                            padding-bottom: 30px;

                            > div {
                                width: calc( 50% - 10px );
                                display: block;

                                * {
                                    display: block; } }

                            label {
                                font-size: 18px;
                                margin: 0 0 4px; }

                            input {
                                padding: 15px;
                                font-size: 16px;
                                width: 100%;
                                margin-top: 10px; } }

                        &-toggle {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            span {
                                display: block;
                                margin: 0 0 4px; }

                            .react-toggle {
                                // &::before
                                //     content: 'Yes'

                                // &::after
 } } }                                //     content: 'No'

                    &-review {
                        display: flex;
                        flex-direction: column;

                        &-copyCont {
                            display: flex;
                            justify-content: flex-start; }

                        &-image {
                            width: 50%;
                            height: 300px;
                            margin: 0 30px 0 0;
                            background-color: $blue;
                            border-radius: 5px;
                            overflow: hidden;
                            background-position: center;
                            background-size: cover;
                            box-shadow: 0 5px 30px rgba(0,0,0,0.2);
                            transition: opacity 0.3s;

                            .loading {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                > svg {
                                    height: 100px;
                                    width: 100%; } } }

                        &-details {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            width: 50%;

                            .copy {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start; }

                            .vision {
                                margin: 10px 0;
                                width: 100%;
                                overflow-wrap: break-word;
                                word-break: break-word; }

                            .name {
                                font-weight: bold;
                                font-size: 25px; }

                            .role, .anonymous {
                                font-size: 18px;
                                color: #999; } } }

                    &-thankyou {
                        max-width: 500px;
                        margin: 0 auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        h3 {
                            margin: 0 0 10px; }

                        .button {
                            margin: 20px 0 30px; } }

                    &-controls {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        width: 100%;

                        .button {
                            &.firstButton {
                                background-color: transparent;
                                color: #999; }

                            &:last-of-type {
                                min-width: 100px; } } } } } } } }





@media(max-width: 1000px) {

    .Modal {

        &>div {
            &.SUBMISSION {
                width: 95%;

                .Submission {
                    &-slide {
                        &-main {
                            padding: 20px 20px 0;
                            position: relative; }

                        &-image {
                            margin-top: 0;
                            height: 100%;

                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: -20px;
                                width: 20px;
                                height: calc(100% - 80px);
                                background: linear-gradient(to right, rgba(255,255,255, 0), white);
                                z-index: 2; }

                            &-main-fields {
                                .list-outer {
                                    overflow: auto;
                                    margin-right: -20px;
                                    padding-right: 25px;
                                    padding-left: 0;
                                    padding-top: 3px;

                                    ul {
                                        width: 185%; } } } }

                        &-vision {
                            height: 100%;
                            justify-content: flex-start;

                            &-image {
                                margin-right: 0;
                                width: 100%;
                                height: 30%; }

                            &-main {
                                justify-content: space-between;
                                padding-top: 10px;

                                &-fields {
                                    display: block;

                                    .selected-image {
                                        width: 100%;
                                        margin-bottom: 25px; }

                                    > div {
                                        width: 100%;

                                        &.gallery-selector {
                                            width: 100%;
                                            display: grid;
                                            grid-template-columns: auto 1fr;
                                            text-align: left;
                                            margin-bottom: 25px;

                                            div {
                                                padding-left: 20px;

                                                .button {
                                                    display: none; } }

                                            img {
                                                width: 45px;
                                                margin: 0px; } } }

                                    label {
                                        font-size: 20px; }

                                    textarea {
                                        height: 210px;
                                        font-size: 22px; }

                                    .maxChars {
                                        font-size: 20px; } }

                                .Submission-slide-controls {
                                    flex-direction: row-reverse; } } }

                        &-value {
                            flex-direction: column;
                            height: 100%;
                            justify-content: flex-start;

                            &-main {
                                padding-top: 10px;

                                &-fields {
                                    width: 100%;
                                    .tags {
                                        width: 100%;
                                        padding-top: 10%;
                                        font-size: 20px;
                                        grid-gap: 8px;
                                        flex-direction: column;

                                        .tag {
                                            padding: 20px 0;
                                            width: 100%; } } } } }

                        &-details {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            &-fields {
                                display: flex;
                                flex-direction: column;
                                grid-gap: 20px;
                                padding-bottom: 40px;

                                input {
                                    font-size: 20px; }

                                &:first-of-type {
                                    div {
                                        &:first-child, &:nth-child(2) {
                                            width: 100%; } } } } }

                        &-thankyou {
                            height: 100%;
                            justify-content: center; }

                        &-review {
                            flex-direction: column;
                            height: 100%;
                            justify-content: space-between;

                            &-copyCont {
                                flex-direction: column;
                                height: 100%;
                                justify-content: space-between; }

                            &-image {
                                height: 190px;
                                width: 100%; }

                            &-details {
                                width: 100%;
                                margin-top: 10px; }

                            .Submission-slide-controls {
                                margin-top: 21px; } }

                        &-controls {
                            margin-top: 10px;
                            height: 65px;
                            justify-content: space-between;
                            align-items: normal;
                            bottom: -2px;
                            width: calc(100% + 40px);
                            margin-left: -20px;

                            .button {
                                width: 50%;
                                border-radius: 0;
                                font-weight: bold;

                                &.firstButton {
                                    background-color: $lightGrey; } } } } } } } } }






@media(max-width: 500px) {

    .Modal {
        > div {
            &.SUBMISSION {
                .Submission-slide-image-main-fields {
                    .list-outer {
                        padding-right: 20px;

                        ul {
                            width: 240%; } } } } } } }
