@import 'Styles/variables';

.FunBiologyMasonry {
    padding: 100px 0;

    &.in-view {
        .FunBiologyMasonry {
            &-content {
                opacity: 1;
                transform: scale(1); } } }

    &-cont {
        padding: 20% 0 0;

        > svg {
            width: 100%;
            height: auto;
            display: block;
            position: absolute;
            top: 0;
            left: 0; } }

    &-content {
        width: 100%;
        transition-delay: 0.15s;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
        opacity: 0;
        transform: translateY(10px);
        transition: 1s;

        &-title {
            font-size: 55px;
            width: 50%;
            line-height: 1;
            padding-bottom: 20px;

            em {
                font-style: inherit;
                color: $purple; } }

        &-copy {
            font-size: 23px;
            width: 60%;
            line-height: 1.5;
            letter-spacing: 0.1px; } }

    .MasonryGrid {
        max-width: 100%;
        margin: 0 auto;

        &.visible {
            opacity: 1;
            transform: translateY(0); }

        &::after {
            content: "";
            display: block;
            clear: both; } }

    &-sizer, &-image {
        width: 33.33333%;
        padding: 8px;

        > div {
            width: 100%;
            padding: 0;
            border-radius: 10px;
            overflow: hidden;
            background-color: $red;
            opacity: 0;
            transform: translateY(20px);
            transition: 1s;

            &.white {
                background-color: white; }

            &.in-view {
                opacity: 1;
                transform: scale(1);

                img {
                    opacity: 1;
                    transform: scale(1); } } }

        img {
            opacity: 0;
            transform: scale(1.2);
            transition: 1s; } } }






@media(max-width: 1024px) {

    .FunBiologyMasonry {
        padding: 60px 0;

        &-cont {
            padding: 30% 0 0; }

        &-content {
            width: 100%;
            padding-top: 0;

            h2 {
                width: 100%;
                font-size: 36px; }

            &-title {
                width: 100%; }

            &-copy {
                width: 100%; } }

        &-sizer, &-image {
            width: 50%;
            padding: 8px; } } }
