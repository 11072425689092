@import 'Styles/variables';

.VideoTabs {
    padding: 150px 0 0;
    background-color: white;
    z-index: 2;

    .container {
        display: flex;
        justify-content: center; }

    &-splitTitle {
        display: flex;
        align-items: flex-start;
        font-size: 18px;
        letter-spacing: 0.4px;

        svg {
            width: 100px;
            margin: 0 5px; } }

    &-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 0;

        &-tab {
            width: 50%;
            padding: 0 60px 30px;
            -webkit-text-fill-color: #ffffff00;
            -webkit-text-stroke-width: 1px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
                opacity: 0;
                visibility: hidden;
                transition: 0.3s; }

            &:first-of-type {
                color: $red;
                -webkit-text-stroke-color: $red;
                border-right: 1px solid darken($lightGrey, 20%);
                justify-content: flex-end; }

            &:nth-child(2) {
                h4 {
                    color: $blue;

                    &:nth-child(2) {
                        color: $blue;
                        -webkit-text-stroke-color: $blue;
                        -webkit-text-fill-color: $blue; } } }

            &:hover {
                h4 {
                    &:nth-child(2) {
                        opacity: 1;
                        visibility: visible; } } }

            h4 {
                font-size: 90px;
                display: flex;
                justify-content: flex-start;
                transition: 0.3s;

                span {
                    font-size: 30px;
                    padding: 20px 10px 0 0;
                    -webkit-text-fill-color: #ffffff00; }

                &:nth-child(2) {
                    position: absolute;
                    top: 0;
                    opacity: 0;
                    visibility: hidden;
                    -webkit-text-stroke-color: $red;
                    -webkit-text-fill-color: $red;

                    span {
                        -webkit-text-fill-color: #ffffff00; } } }

            &.selected {
                &::before {
                    opacity: 1;
                    visibility: visible; }

                h4 {
                    &:nth-child(1) {
                        opacity: 0;
                        visibility: hidden; }

                    &:nth-child(2) {
                        opacity: 1;
                        visibility: visible; } } } } }

    &-videoCont {
        width: 100%;
        background: grey;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .playButton {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.3s;
            position: absolute;

            svg {
                path {
                    stroke: white; } }
            p {
                text-align: center;
                color: white;
                font-size: 22px;
                padding-top: 10px; }

            &.playing {
                    display: none; }

            &:hover {
                transform: scale(1.1); } }

        video {
            position: relative;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0; } } }





@media(max-width: 1024px) {

    .VideoTabs {
        padding: 100px 0 0;

        .container {
            flex-direction: column; }

        &-splitTitle {
            text-align: center;
            justify-content: center;
            flex-wrap: wrap; }

        &-tabs {
            flex-direction: column;
            padding: 20px 0 40px;

            &-tab {
                width: 100%;
                justify-content: center;
                padding: 0 60px;

                &::before {
                    display: none; }

                &:first-of-type {
                    justify-content: center; } }

            h4 {
                font-size: 60px;

                span {
                    font-size: 20px; } } }

        &-videoCont {

            .playButton {
                transform: scale(.5);
                p {
                    display: none; } } } } }
